<template>
    <div class="map-page map-page-search">
        <img src="@/assets/images/mobile/blog-header.jpg" class="map-page-header-img" />
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Discover our Resources</h1>
            <base-input
                class="map-search-input"
                :round="true"
                icon-left="icon-search"
                placeholder="Search..."
                v-model="search_term"
                @change="do_search"
                >
            </base-input>
            <basic-dropdown :options="filters" v-model="filter_by" placeholder="Explore our knowledge base by:"></basic-dropdown>
            <dropdown
                v-if="filter_by === 'Language'"
                placeholder="Select your language"
                v-model="topic"
                :closeOnSelect="false"
                :options="topics"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :closeOnSelect="false"
                :options="countries"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Tag'"
                placeholder="Select your tag"
                v-model="tag"
                :closeOnSelect="false"
                :options="tags"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Center'"
                placeholder="Select your center"
                v-model="center"
                :closeOnSelect="false"
                :options="centers"
                >
            </dropdown>
            <articles :style="'search'" :loading="loading">
                <p v-if="!posts || !posts.length">There are no blog posts</p>
                <search-post
                    v-for="(post, key) in posts"
                    :key="key"
                    :image="post.cover_image"
                    :title="post.title"
                    :excerpt="post.summary"
                    :center="post.centers.join(', ')"
                    :link="`/${post.type}/${post.id}`"
                    >
                </search-post>
            </articles>
            <button class="map-button map-load-more" v-if="has_more_pages" @click.prevent="load_more">LOAD MORE</button>
            <div class="map-center-access">
                <p>You have access to blog posts from:</p>
                <router-link
                    :to="{ name: center }"
                    v-for="(center, key) in user.centers"
                    :key="key"
                    >
                    {{ center }}
                </router-link>
            </div>
        </div>
        <map-sidebar class="map-sidebar-blog">
            <!-- <basic-dropdown :options="filters" v-model="filter_by" placeholder="Explore our knowledge base by:"></basic-dropdown>
            <dropdown
                v-if="filter_by === 'Language'"
                placeholder="Select your language"
                v-model="topic"
                :closeOnSelect="false"
                :options="topics"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :closeOnSelect="false"
                :options="countries"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Tag'"
                placeholder="Select your tag"
                v-model="tag"
                :closeOnSelect="false"
                :options="tags"
                >
            </dropdown> -->
        </map-sidebar>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import BasicDropdown from '@/components/Inputs/BasicDropdown';
    import Dropdown from '@/components/Inputs/Dropdown';
    import Articles from '@/components/Articles/Articles';
    import SearchPost from '@/components/Articles/SearchPost';
    import MapSidebar from '@/components/MapSidebar';

    import { PostService, PostType, CountryService, TopicService, TagService } from '@/services';

    export default
    {
        name: 'Search',
        components:
        {
            BaseInput,
            BasicDropdown,
            Dropdown,
            Articles,
            SearchPost,
            MapSidebar,
        },
        data()
        {
            return {
                loading: true,
                has_more_pages: false,
                per_page: 12,
                last_page: 1,
                page: 1,
                posts:[],
                topics: [],
                tags: [],
                centers: [],
                countries: [],
                search_term: this.$route.params.query || '',
                topic: '',
                tag: '',
                country: '',
                center: '',
                filter_by: '',
                filters: [
                    'Country',
                    'Language',
                    'Tag',
                    'Center',
                ],
            };
        },
        watch:
        {
            filter_by()
            {
                this.topic = '';
                this.tag = '';
                this.country = '';
                this.center = '';
            },
            topic()
            {
                this.apply_filters();
            },
            tag()
            {
                this.apply_filters();
            },
            country()
            {
                this.apply_filters();
            },
            center()
            {
                this.apply_filters();
            },
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            this.loading = true;
            await this.get_posts();

            this.countries = (await CountryService.all({ with: PostType.BLOG })).data;
            this.topics = (await TopicService.all({ with: PostType.BLOG })).data;
            this.tags = (await TagService.all({ with: PostType.BLOG })).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.centers = this.user.centers.map((item, i) =>
            {
                console.log(item);

                return {
                    value: i + 2,
                    label: item
                };
            });

            console.log(this.centers);

            this.loading = false;
        },
        methods:
        {
            async get_posts()
            {
                const response = (await PostService.search(this.search_term, {
                    page: this.page,
                    country: this.country,
                    topic: this.topic,
                    tag: this.tag,
                    center: this.center,
                    exclude: 'content,countries,comments',
                    'per-page': this.per_page,
                    'order-by': 'created_at',
                })).data;
                const posts = response;

                if (this.page === 1)
                {
                    this.posts = posts;
                }
                else
                {
                    this.posts.push(...posts);
                }

                // this.last_page = response.meta.last_page;
                // this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async load_more()
            {
                this.loading = true;

                await this.get_posts();

                this.loading = false;
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_posts();

                this.loading = false;
            },

            do_search()
            {
                this.$router.push({ name: 'Search', params: { query: this.search_term } });
                this.apply_filters();
            },
        },
    };
</script>
