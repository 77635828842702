<template>
    <div class="map-page map-page-resources">
        <img src="@/assets/images/mobile/resources-header.jpg" class="map-page-header-img" />
        <div class="map-page-content map-center-layout">
            <h1 class="map-page-title">Discover Our Resources</h1>
            <basic-dropdown :options="filters" v-model="filter_by" placeholder="Explore our knowledge base by:"></basic-dropdown>
            <dropdown
                v-if="filter_by === 'Language'"
                placeholder="Select your language"
                v-model="topic"
                :options="topics"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :options="countries"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Tag'"
                placeholder="Select your tag"
                v-model="tag"
                :options="tags"
                >
            </dropdown>
            <div class="map-center-access">
                <p>You have access to resources posts from:</p>
                <router-link
                    :to="{ name: center }"
                    v-for="(center, key) in user.centers"
                    :key="key"
                    >
                    {{ center }}
                </router-link>
            </div>
            <ul class="map-resources-tabs">
                <li
                    v-for="(type, key) in resource_types"
                    :key="'type_' + key"
                    :class="{ 'map-selected' : type === this.show_resource_type }"
                    @click="change_resource_type(type)"
                    >
                    {{ type }} Resources
                </li>
            </ul>
            <articles :style="'resource'" :loading="loading">
                <p v-if="!posts || !posts.length">There are no resources</p>
                <resource-post
                    v-for="(resource, key) in filtered_posts"
                    :key="key"
                    :featured="key === 0"
                    :image="resource.cover_image"
                    :title="resource.title"
                    :excerpt="resource.summary"
                    :link="'/resource/' + resource.id"
                    >
                </resource-post>
            </articles>
            <button class="map-button map-load-more" v-if="has_more_pages" @click.prevent="load_more">LOAD MORE</button>
        </div>
        <map-sidebar class="map-sidebar-resources">
            <!-- <basic-dropdown :options="filters" v-model="filter_by" placeholder="Explore our knowledge base by:"></basic-dropdown>
            <dropdown
                v-if="filter_by === 'Language'"
                placeholder="Select your language"
                v-model="topic"
                :options="topics"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Country'"
                placeholder="Select your country"
                v-model="country"
                :options="countries"
                >
            </dropdown>
            <dropdown
                v-if="filter_by === 'Tag'"
                placeholder="Select your tag"
                v-model="tag"
                :options="tags"
                >
            </dropdown> -->
        </map-sidebar>
    </div>
</template>

<script>
    import BasicDropdown from '@/components/Inputs/BasicDropdown';
    import Dropdown from '@/components/Inputs/Dropdown';
    import Articles from '@/components/Articles/Articles';
    import ResourcePost from '@/components/Articles/ResourcePost';
    import MapSidebar from '@/components/MapSidebar';

    import { PostService, PostType, CountryService, TopicService, TagService } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            BasicDropdown,
            Dropdown,
            Articles,
            ResourcePost,
            MapSidebar,
        },
        data()
        {
            return {
                loading: true,
                has_more_pages: false,
                per_page: 12,
                last_page: 1,
                page: 1,
                posts:[],
                topics: [],
                tags: [],
                countries: [],
                topic: '',
                tag: '',
                country: '',
                filter_by: '',
                filters: [
                    'Country',
                    'Language',
                    'Tag',
                ],
                show_resource_type: 'General',
                resource_types: [
                    'General',
                    'Media',
                ],
            };
        },
        watch:
        {
            filter_by()
            {
                this.topic = '';
                this.tag = '';
                this.country = '';
            },
            topic()
            {
                this.apply_filters();
            },
            tag()
            {
                this.apply_filters();
            },
            country()
            {
                this.apply_filters();
            },
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
            filtered_posts()
            {
                return this.posts;
            },
        },
        async mounted()
        {
            this.loading = true;
            await this.get_posts();

            this.countries = (await CountryService.all({ with: PostType.RESOURCE })).data;
            this.topics = (await TopicService.all({ with: PostType.RESOURCE })).data;
            this.tags = (await TagService.all({ with: PostType.RESOURCE })).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.loading = false;
        },
        methods:
        {
            async get_posts()
            {
                const response = (await PostService.all(PostType.RESOURCE, {
                    page: this.page,
                    country: this.country,
                    topic: this.topic,
                    tag: this.tag,
                    exclude: 'content',
                    'media-resource': this.show_resource_type === 'Media' ? 1 : 0,
                    'per-page': this.per_page,
                    'order-by': 'created_at',
                })).data;
                const posts = response.data;

                if (this.page === 1)
                {
                    this.posts = posts;
                }
                else
                {
                    this.posts.push(...posts);
                }

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async load_more()
            {
                this.loading = true;

                await this.get_posts();

                this.loading = false;
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_posts();

                this.loading = false;
            },

            async change_resource_type(type)
            {
                this.show_resource_type = type;

                this.apply_filters();
            },
        },
    };
</script>

<style lang="less" scoped>
.map-resources-tabs
{
    margin-bottom: 2rem;
    gap: 1rem;
    display: flex;
    width: 30rem;

    li
    {
        white-space: nowrap;
        padding: 0 8rem;
        align-items: center;
        display: flex;
        width: 100%;
        height: 11.4rem;
        border-radius: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        background-color: #ea345794;

        &.map-selected,
        &:hover
        {
            background-color: var(--primary-color);
        }
    }
}

@media only screen and (min-width: 1243px)
{
    .map-resources-tabs
    {
        li
        {
            padding: 0 2.5rem;
            height: 4.8rem;
            font-size: 1.2rem;
        }
    }
}
</style>